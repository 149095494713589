import React from 'react';
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
} from 'reactstrap';

const CommonConfirmationModal = (props) => {
  const {
    closeModal,
    onCancelHandler,
    isLoading,
    cancelButtonText = '',
    successButtonText = '',
    modalHeading = '',
    modalContent = '',
    cancelButtonClassName = '',
    successButtonClassName = '',
    modalWrapperClassName = '',
    onSuccessHandler,
  } = props;

  return (
    <Modal
      isOpen
      centered
      toggle={closeModal}
      className={`${modalWrapperClassName} bh-common-modal bh-generic-modal`}
    >
      <ModalHeader toggle={closeModal} />
      <ModalBody>
        <div>
          <h3>{modalHeading}</h3>
          {modalContent}
        </div>
        <div className="bh-action">
          <Button
            block
            color="primary"
            disabled={isLoading}
            className={`${cancelButtonClassName}`}
            onClick={onCancelHandler}
          >
            {cancelButtonText}
          </Button>
          {successButtonText ? (
            <Button
              block
              color="secondary"
              disabled={isLoading}
              className={`${successButtonClassName}`}
              onClick={onSuccessHandler}
            >
              {successButtonText}
            </Button>
          ) : ''}
        </div>
      </ModalBody>
    </Modal>
  );
};

export default CommonConfirmationModal;
