import React, { useCallback, useEffect, useState } from 'react';
import { Button } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { getOpenWorkOrders } from '../../services/services';
import Loader from '../../shared/loader';
import { setOpenWorkOrders, reset } from '../../reducers/tenant-job-entry';
import { getLocalStorage } from '../../../helpers/utils';

const WorkOrderInfo = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isFetchingWorkOrders, setIsFetchingWorkOrders] = useState(false);

  const openWorkOrders = useSelector((state) => state?.tenantJobEntry?.open_work_orders);
  const residentInfo = useSelector((state) => state?.tenantJobEntry?.residentInfo);
  const token = getLocalStorage('userAccessToken');

  useEffect(() => {
    setIsFetchingWorkOrders(true);
    getOpenWorkOrders().then((res) => {
      dispatch(setOpenWorkOrders(res));
    }).catch(() => {
    }).finally(() => setIsFetchingWorkOrders(false));
  }, [dispatch]);

  const navigateToPropertyInfoPage = useCallback(() => {
    if (token) {
      localStorage.removeItem('userAccessToken');
      dispatch(reset());
      const URL = `${window.location.origin}/property-info`;
      window.location.replace(URL);
    }
  }, [dispatch, token]);

  const renderContent = () => {
    if (isFetchingWorkOrders) {
      return <Loader />;
    }
    if (token) {
      return (
        <div className="content-wrapper">
          <div className="bh-work-order-data">
            <div>
              <div className="bh-work-card">
                <h3>New Work Order</h3>
                <p>Submit a new maintenance request</p>
                <Button
                  type="submit"
                  color="primary"
                  className="btn-primary-bh"
                  block
                  onClick={() => navigate('/service-category')}
                >
                  Submit Maintenance Request
                </Button>
              </div>
              <div className="bh-work-card">
                <h3>View Work Orders</h3>
                <p>View all open maintenance requests</p>
                {isFetchingWorkOrders ? (
                  <Loader />
                ) : ''}
                {((openWorkOrders || [])?.length > 0 ? (
                  <Button
                    onClick={() => navigate('/work-order-listing')}
                    type="submit"
                    color="primary"
                    block
                    outline
                  >
                    View Open Work Orders (
                    {(openWorkOrders || [])?.length}
                    )
                  </Button>
                ) : (
                  <div className="no-order">
                    <p>There are no open work orders</p>
                  </div>
                )
            )}
              </div>
            </div>
            <div className="sub-contet-wrapper text-center">
              <p>{residentInfo?.address}</p>
              <Button
                type="button"
                className="support-line"
                onClick={navigateToPropertyInfoPage}
              >
                Not your address? Click here
              </Button>
            </div>
          </div>
        </div>
      );
    }
    return <Loader />;
  };

  return (
    <div className="info-form">
      {renderContent()}
    </div>
  );
};

export default WorkOrderInfo;
