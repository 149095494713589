import React, { useCallback } from 'react';
import { Form, Button } from 'reactstrap';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';

import { yupResolver } from '@hookform/resolvers/yup';

import TextAreaField from '../../shared/form-field/text-area';
import { setDescription } from '../../reducers/tenant-job-entry';

const Description = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const description = useSelector((state) => state.tenantJobEntry?.description);

  const methods = useForm({
    mode: 'all',
    defaultValues: {
      description: description || '',
    },
    resolver: yupResolver(
      yup.object().shape({
        description: yup.string().trim().required('Description is required'),
      }),
    ),
  });

  const { handleSubmit } = methods;

  const onSubmit = useCallback((data) => {
    dispatch(setDescription(data?.description));
    navigate('/upload-media');
  }, [dispatch, navigate]);

  return (
    <FormProvider {...methods}>
      <Form className="info-form" onSubmit={handleSubmit(onSubmit)}>
        <div className="content-wrapper">
          <div className="step">Step 2/5</div>
          <h2>Description</h2>
          <p>Please enter detailed information about your issue below.</p>
          <div className="resident-info-data">
            <div className="bh-description">
              <TextAreaField
                name="description"
                className="form-control"
                placeholder="Please enter detailed description of your problem"
              />
            </div>
          </div>
        </div>
        <div className="footer-action">
          <div className="container d-flex gap-3">
            <Button
              type="button"
              color="secondary"
              block
              onClick={() => navigate('/service-category')}
            >
              Back
            </Button>
            <Button
              type="submit"
              color="primary"
              className="btn-primary-bh"
              block
            >
              Continue
            </Button>
          </div>
        </div>
      </Form>
    </FormProvider>
  );
};

export default Description;
