import './scss/style.scss';
import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { allRoutes } from './components/routes/bridge-homes-routes';

const App = () => {
  const router = createBrowserRouter(allRoutes);
  return <RouterProvider router={router} />;
};

export default App;
