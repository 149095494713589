import React from 'react';

const HotWaterHeater = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="28" height="37" viewBox="0 0 28 37" fill="none" {...props}>
    <rect x="1.36719" y="0.890625" width="25.2656" height="35.2188" rx="3.0625" stroke="#1369E9" strokeWidth="1.53125" />
    <rect x="6.21838" y="5.74854" width="15.5633" height="15.5633" rx="1.72266" stroke="#1369E9" strokeWidth="1.53125" />
    <path d="M11.3181 17.1602C9.62363 13.8152 12.1788 13.2531 12.7436 11.679C13.1955 10.4197 12.9319 9.44903 12.7436 9.12109C13.6222 9.68327 15.3257 11.4091 15.1105 13.8152C15.6054 12.2861 15.8188 11.4728 15.8636 11.2573C16.6615 12.1475 17.9615 14.5742 16.7781 17.1602" stroke="$primary-color" strokeWidth="1.53125" strokeLinecap="round" strokeLinejoin="round" />
    <line x1="1.29468" y1="28.4663" x2="10.535" y2="28.4663" stroke="#1369E9" strokeWidth="1.53125" />
    <line x1="18.0195" y1="28.4531" x2="26.4414" y2="28.4531" stroke="#1369E9" strokeWidth="1.53125" />
    <circle cx="13.8845" cy="28.5884" r="4.11523" stroke="#1369E9" strokeWidth="1.53125" />
    <line x1="13.769" y1="28.7761" x2="14.9964" y2="27.5488" stroke="#1369E9" strokeWidth="1.53125" strokeLinecap="round" />
  </svg>

);
export default HotWaterHeater;
