import React from 'react';
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
} from 'reactstrap';

const MoreDetailsInfoModal = ({ closeModal, serviceCategories, wrapperClassName }) => (
  <Modal isOpen toggle={closeModal} centered modalClassName={wrapperClassName}>
    <ModalHeader toggle={closeModal} />
    <ModalBody>
      <h2>Service Categories</h2>
      <div className="modal-data-card">
        {serviceCategories?.map((item) => (
          <div className="data-card--item" key={item?.service_category_id}>
            <h4>{item?.label}</h4>
            <p>{item?.short_description}</p>
          </div>
        ))}
      </div>
      <div className="modal-action">
        <Button color="secondary" block onClick={closeModal}>
          Close
        </Button>
      </div>
    </ModalBody>
  </Modal>
);

export default MoreDetailsInfoModal;
