import React from 'react';
import { Navigate } from 'react-router-dom';
import PropertyInfo from '../bridge-homes/property-info';
import AddNewPropertyInfo from '../bridge-homes/add-new-property';
import WorkOrderInfo from '../bridge-homes/work-order-info';
import WorkOrderListing from '../bridge-homes/work-order-listing';
import ServiceCategory from '../bridge-homes/service-category';
import UploadMedia from '../bridge-homes/upload-media';
import Description from '../bridge-homes/description';
import WorkOrderSuccess from '../bridge-homes/work-order-success';
import AddAvailability from '../bridge-homes/add-availability';
import AddPropertyAccessDetails from '../bridge-homes/property-access-details';
import PortalLayout from '../layout/bridge-homes-layout/portal';
import BridgeHomesLayout from '../layout/bridge-homes-layout';

export const allRoutes = [
  {
    path: '/',
    element: <PortalLayout />,
    children: [
      {
        index: true,
        element: <Navigate to="/work-order-info" />,
        key: 'index',
      },
      {
        path: 'work-order-info',
        element: <WorkOrderInfo />,
        key: 'work-order-info',
      },
      {
        path: 'work-order-listing',
        element: <WorkOrderListing />,
        key: 'work-order-listing',
      },
      {
        path: 'service-category',
        element: <ServiceCategory />,
        key: 'service-category',
      },
      {
        path: 'property-access-details',
        element: <AddPropertyAccessDetails />,
        key: 'property-access-details',
      },
      {
        path: 'upload-media',
        element: <UploadMedia />,
        key: 'upload-media',
      },
      {
        path: 'availability',
        element: <AddAvailability />,
        key: 'availability',
      },
      {
        path: 'description',
        element: <Description />,
        key: 'description',
      },
      {
        path: 'success',
        element: <WorkOrderSuccess />,
        key: 'success',
      },
    ],
  },
  {
    path: '/property-info',
    element: (
      <BridgeHomesLayout>
        <PropertyInfo />
      </BridgeHomesLayout>
    ),
    key: 'property-info',
  },
  {
    path: '/add-new-property',
    element: (
      <BridgeHomesLayout>
        <AddNewPropertyInfo />
      </BridgeHomesLayout>
    ),
    key: 'add-new-property',
  },
];

export const authRoutes = [
  {
    path: '/property-info',
    element: <PropertyInfo />,
    key: 'property-info',
  },
  {
    path: '/add-new-property',
    element: <AddNewPropertyInfo />,
    key: 'add-new-property',
  },
  {
    redirect: true,
    path: '/',
    to: '/property-info',
    key: 'redirect',
  },
  {
    redirect: true,
    path: '*',
    to: '/property-info',
    key: 'redirect',
  },
];

export const portalRoutes = [
  {
    path: '/work-order-info',
    element: <WorkOrderInfo />,
    key: 'work-order-info',
  },
  {
    path: '/work-order-listing',
    element: <WorkOrderListing />,
    key: 'work-order-listing',
  },
  {
    path: '/service-category',
    element: <ServiceCategory />,
    key: 'service-category',
  },
  {
    path: '/property-access-details',
    element: <AddPropertyAccessDetails />,
    key: 'property-access-details',
  },
  {
    path: '/upload-media',
    element: <UploadMedia />,
    key: 'upload-media',
  },
  {
    path: '/availability',
    element: <AddAvailability />,
    key: 'availability',
  },
  {
    path: '/description',
    element: <Description />,
    key: 'description',
  },
  {
    path: 'success',
    element: <WorkOrderSuccess />,
    key: 'success',
  },
];
