export const timeWindows = {
  anytime: 'Anytime (8am-8pm)',
  morning: 'Morning (8am-12pm)',
  afternoon: 'Afternoon (12pm-4pm)',
  evening: 'Evening (4pm-8pm)',
};

export const bhTimeWindows = {
  anytime: {
    label: 'Anytime',
    timeSlot: '8am-8pm',
  },
  morning: {
    label: 'Morning',
    timeSlot: '8am-12pm',
  },
  afternoon: {
    label: 'Afternoon',
    timeSlot: '12pm-4pm',
  },
  evening: {
    label: 'Evening',
    timeSlot: '4pm-8pm',
  },
};

export const emergencyReason = [
  {
    id: 'c9a0bd50-f467-5e63-b93f-ff1454138b2c',
    label: 'No Heating or Cooling in Extreme Temperatures',
  },
  {
    id: '034c0f4b-3c2b-5cbd-b610-dc4f214025b6',
    label: 'Active Leak or Flooding of Home',
  },
  {
    id: '2e23cd8e-4948-512d-86f3-dbdb7c002eae',
    label: 'Plumbing Backup or Clog in Multiple Plumbing Fixtures',
  },
  {
    id: '9402349d-e0b0-5d46-8349-1ef2d878ed19',
    label: 'Security or Safety Concerns (i.e. front door can"t be shut)',
  },
  {
    id: '49e44951-951a-50b1-9ef5-a0260efd6bd2',
    label: 'Health Hazard ',
  },
  {
    id: 'e8cb2cfa-8ae2-5035-8e65-14e56fbae492',
    label: 'Locked out of Home',
  },
  {
    id: '8ccade4b-2025-50ce-8c8e-fa6688595d47',
    label: 'Refrigerator is not Cooling',
  },
  {
    id: '90415bf3-befd-54bc-be71-222c04af9767',
    label: 'Fire/Smoke',
  },
  {
    id: 'a9cea206-9fab-5811-99f2-bac62a87a3a9',
    label: 'No Hot Water',
  },
  {
    id: '24fcc7c3-1db3-5950-bd07-76c453ed1f2d',
    label: 'No Electricity anywhere in Home',
  },
  {
    id: '57bcbbf8-fd9f-5985-a5de-d87ae487fa86',
    label: 'No Working Toilets',
  },
];

export const bufferTime = 0; // hours
