import React from 'react';
import LulaLogo from './lula-logo';
import ServiceLogo from './service-logo';
import Close from './close';
import HandyMan from './handyman';
import Plumbing from './plumbing';
import Appliance from './appliance';
import HeatingCooling from './heating-cooling';
import Electrical from './electrical';
import HotWaterHeater from './hot-water-heater';
import DrainCleaning from './drain-cleaning';
import Other from './other';
import Calander from './calander';
import CalanderWhite from './calander-white';
import FileUpload from './file-upload';
import CloseSolid from './close-solid';
import Submitted from './submitted';
import StartIcon from './start-icon';

export const LulaLogoIcon = (props) => <LulaLogo {...props} />;
export const ServiceLogoIcon = (props) => <ServiceLogo {...props} />;
export const CloseIcon = (props) => <Close {...props} />;
export const HandyManIcon = (props) => <HandyMan {...props} />;
export const PlumbingIcon = (props) => <Plumbing {...props} />;
export const ApplianceIcon = (props) => <Appliance {...props} />;
export const HeatingCoolingIcon = (props) => <HeatingCooling {...props} />;
export const ElectricalIcon = (props) => <Electrical {...props} />;
export const HotWaterHeaterIcon = (props) => <HotWaterHeater {...props} />;
export const DrainCleaningIcon = (props) => <DrainCleaning {...props} />;
export const OtherIcon = (props) => <Other {...props} />;
export const CalendarIcon = (props) => <Calander {...props} />;
export const CalanderWhiteIcon = (props) => <CalanderWhite {...props} />;
export const FileUploadIcon = (props) => <FileUpload {...props} />;
export const CloseSolidIcon = (props) => <CloseSolid {...props} />;
export const SubmittedIcon = (props) => <Submitted {...props} />;
export const StartLogoIcon = (props) => <StartIcon {...props} />;
