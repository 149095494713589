import { isEmpty } from 'lodash';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, Input } from 'reactstrap';

import CommonConfirmationModal from '../modal/common-confirmation-modal';
import AddEditAvailabilityModal from '../modal/add-edit-availability-modal-v2';

import { setJobEmergency } from '../../reducers/tenant-job-entry';
import { emergencyReason } from '../../config';

const AddAvailability = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const formErrors = useSelector(
    (state) => state.tenantJobEntry?.summaryFormErrors,
  );
  const isEmergencyJob = useSelector(
    (state) => state.tenantJobEntry?.is_emergency,
  );

  const jobSchedules = useSelector(
    (state) => state.tenantJobEntry?.job_schedules,
  );

  const [availability, setAvailability] = useState({
    avail_1: {},
    avail_2: {},
    avail_3: {},
  });
  const [showAddEditAvailabilityModal, setShowAddEditAvailabilityModal] = useState(false);
  const [confirmationModalData, setConfirmationModalData] = useState({});

  const isDateValid = (date) => {
    const formattedDate = moment(date).format('YYYY-MM-DD');
    if (formErrors?.job_schedules?.dates.includes(formattedDate)) {
      return 'error-schedule';
    }
    return '';
  };

  const handleJobEmergencySwitch = useCallback(() => {
    dispatch(setJobEmergency(!isEmergencyJob));
  }, [dispatch, isEmergencyJob]);

  const onEmergencyButtonClickHandler = () => {
    if (!isEmergencyJob) {
      setConfirmationModalData({
        modalHeading: 'Is This an Emergency?',
        modalContent: emergencyReasonContent(),
        cancelButtonText: 'I Confirm, My Job is Emergency',
        successButtonText: 'Close',
        cancelButtonClassName: 'btn-primary-bh',
        onSuccessHandler: () => setConfirmationModalData({}),
        closeModal: () => setConfirmationModalData({}),
        onCancelHandler: () => {
          setConfirmationModalData({});
          handleJobEmergencySwitch();
        },
      });
    } else {
      handleJobEmergencySwitch();
    }
  };

  const onContinueClickHandler = () => {
    if (!isEmergencyJob) {
      const jobScheduleKeys = Object.keys(jobSchedules || {});
      const filteredItems = jobScheduleKeys?.filter(
        (item) => !isEmpty(jobSchedules?.[item]),
      );
      const isOnlyEveningHasSelected = jobScheduleKeys?.filter(
        (item) => jobSchedules?.[item]?.timings?.every((selectedTiming) => selectedTiming === 'evening'),
      );
      if (filteredItems?.length < 3) {
        setConfirmationModalData({
          modalHeading: 'Not Enough Availabilities',
          modalWrapperClassName: 'bh-confirmation-modal',
          modalContent: (
            <p>
              You&apos;ve selected
              {' '}
              <span>
                {filteredItems?.length}
                {' '}
                date(s)
              </span>
              {' '}
              for your
              appointment. Three dates are required to schedule your appointment
              more efficiently.
            </p>
          ),
          cancelButtonText: 'Add More Dates',
          cancelButtonClassName: 'btn-primary-bh',
          closeModal: () => setConfirmationModalData({}),
          onCancelHandler: () => setConfirmationModalData({}),
        });
      } else if (isOnlyEveningHasSelected?.length === 3) {
        setConfirmationModalData({
          modalHeading: 'Normal business hour window is required',
          modalWrapperClassName: 'bh-confirmation-modal',
          modalContent: (
            <p>
              You have selected all 3 windows as Evening.
              Please select at least 1 normal business hour
              window (Morning or After Noon) to continue.
            </p>
          ),
          cancelButtonText: 'Edit Selected Dates',
          cancelButtonClassName: 'btn-primary-bh',
          closeModal: () => setConfirmationModalData({}),
          onCancelHandler: () => setConfirmationModalData({}),
        });
      } else {
        navigate('/property-access-details');
      }
    } else {
      navigate('/property-access-details');
    }
  };

  const emergencyReasonContent = () => (
    <div className="inner-content">
      <p>These are examples of what is considered an emergency:</p>
      <ul>
        {emergencyReason?.map((item) => (
          <li key={item?.id}>{item?.label}</li>
        ))}
      </ul>
    </div>
  );

  const getTimeSlotLabel = (selectedTimeSlots = []) => {
    if (selectedTimeSlots?.includes('anytime')) return 'Anytime';
    return selectedTimeSlots
      ?.map(
        (item) => `${item?.charAt(0)?.toUpperCase()}${item?.substr(1)?.toLowerCase()}`,
      )
      .join(', ');
  };

  const isButtonDisabled = useCallback(() => {
    const lengthOfAvailability = Object.keys(availability || {})?.filter(
      (key) => isEmpty(availability[key]),
    )?.length;
    if (!isEmergencyJob) {
      return !!lengthOfAvailability;
    }
    return false;
  }, [availability, isEmergencyJob]);

  useEffect(() => {
    if (!isEmpty(formErrors?.job_schedules?.dates)) {
      const scrollElement = document?.getElementById(
        `schedule-date-${formErrors?.job_schedules?.dates[0]}`,
      );
      if (scrollElement) {
        scrollElement.scrollIntoView({
          behavior: 'smooth',
          block: 'end',
          inline: 'nearest',
        });
      }
    }
  }, [formErrors?.job_schedules?.dates]);

  useEffect(() => {
    if (!isEmpty(jobSchedules)) {
      setAvailability(jobSchedules);
    }
  }, [jobSchedules]);

  return (
    <>
      <div className="info-form">
        <div className="content-wrapper">
          <div className="step">Step 4/5</div>
          <h2>Select Your Availability</h2>
          <p>
            Your request requires you to select 3 different dates for
            availability.
          </p>
          <div className="availability-selection">
            <div
              className="property-card"
              onClick={onEmergencyButtonClickHandler}
              role="presentation"
            >
              <Input
                type="radio"
                name="radio1"
                checked={isEmergencyJob ?? false}
                readOnly
              />
              <div className="property-card--inner">
                <div className="virtual-checkbox" />
                <div className="property-data">
                  <div className="customer-name">My Job is Emergency</div>
                  <p>I need this service as soon as possible</p>
                </div>
              </div>
            </div>
            {!isEmergencyJob ? (
              <React.Fragment>
                {' '}
                {Object.keys(availability)?.map((key) => {
                  const data = availability[key];
                  return (
                    <React.Fragment key={`${key}-list`}>
                      {!isEmpty(data) ? (
                        <div
                          className={`edit-availability schedule-added ${
                            data?.date ? isDateValid(data?.date) : ''
                          }`}
                          id={`${
                            data?.date
                              ? `schedule-date-${moment(data?.date).format(
                                'YYYY-MM-DD',
                              )}`
                              : ''
                          }`}
                        >
                          <div className="edit-availability-content">
                            <div>
                              <h3>
                                {moment(data?.date)?.format('ddd MMM DD, YYYY')}
                              </h3>
                              <h5>{getTimeSlotLabel(data?.timings)}</h5>
                            </div>
                            <Button
                              className="edit-schedule"
                              onClick={() => setShowAddEditAvailabilityModal(key)}
                            >
                              Edit
                            </Button>
                          </div>
                          {data?.constraints
                            ? (
                              <h5 className="text-danger">
                                {data?.constraints}
                              </h5>
                            )
                            : ''}
                        </div>
                      ) : (
                        ''
                      )}
                    </React.Fragment>
                  );
                })}
                {Object.keys(availability)?.map((key, index) => {
                  const data = availability[key];
                  return isEmpty(data) ? (
                    <div
                      onClick={() => setShowAddEditAvailabilityModal(key)}
                      className="add-availability"
                      role="presentation"
                      key={`${key}-button`}
                    >
                      Add Availability Option
                      {' '}
                      {index + 1}
                    </div>
                  ) : (
                    ''
                  );
                })}
              </React.Fragment>
            ) : (
              ''
            )}
          </div>
          {isButtonDisabled() ? (
            <div className="information-card">
              <p>
                Fill out all options which helps us schedule your appointment
                more efficiently.
              </p>
            </div>
          ) : (
            ''
          )}
        </div>
        <div className="footer-action">
          <div className="container d-flex gap-3">
            <Button
              color="secondary"
              block
              type="button"
              onClick={() => navigate('/upload-media')}
            >
              Back
            </Button>
            <Button
              type="submit"
              color="primary"
              className="btn-primary-bh"
              block
              onClick={onContinueClickHandler}
            >
              Continue
            </Button>
          </div>
        </div>
      </div>
      {!isEmpty(confirmationModalData) ? (
        <CommonConfirmationModal
          modalHeading={confirmationModalData?.modalHeading}
          modalContent={confirmationModalData?.modalContent}
          cancelButtonText={confirmationModalData?.cancelButtonText}
          successButtonText={confirmationModalData?.successButtonText}
          modalWrapperClassName={confirmationModalData?.modalWrapperClassName}
          cancelButtonClassName={confirmationModalData?.cancelButtonClassName}
          onSuccessHandler={confirmationModalData?.onSuccessHandler}
          closeModal={confirmationModalData?.closeModal}
          onCancelHandler={confirmationModalData?.onCancelHandler}
        />
      ) : (
        ''
      )}
      {showAddEditAvailabilityModal ? (
        <AddEditAvailabilityModal
          availability={availability}
          availabilityKey={showAddEditAvailabilityModal}
          closeModal={() => setShowAddEditAvailabilityModal('')}
        />
      ) : (
        ''
      )}
    </>
  );
};

export default AddAvailability;
