import React, { useCallback, useState } from 'react';
import { Form, Button } from 'reactstrap';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { isEmpty } from 'lodash';
import ImageUploader from '../../shared/image-uploader';
import CommonConfirmationModal from '../modal/common-confirmation-modal';

const UploadMedia = () => {
  const [uploadImages, setUploadImages] = useState([]);
  const [
    showImageUploadConfirmationModal,
    setShowImageUploadConfirmationModal,
  ] = useState(false);

  const uploadedImage = useSelector(
    (state) => state.tenantJobEntry?.job_medias,
  );

  const navigate = useNavigate();
  const methods = useForm({
    defaultValues: {
      images: [],
    },
  });

  const handleNextStepClickHandler = useCallback(() => {
    if (isEmpty(uploadedImage)) {
      setShowImageUploadConfirmationModal(true);
    } else {
      navigate('/availability');
    }
  }, [navigate, uploadedImage]);

  const onConfirmClickHandler = useCallback(() => {
    setShowImageUploadConfirmationModal(false);
    navigate('/availability');
  }, [navigate]);

  const isUploading = () => Object.keys(uploadImages)?.some((key) => uploadImages[key]?.isLoading);

  return (
    <>
      <FormProvider {...methods}>
        <Form className="info-form">
          <div className="content-wrapper">
            <div className="step">Step 3/5</div>
            <h2>Upload Photos</h2>
            <p>
              Please upload photos to help our team better understand the
              situation.
            </p>
            <div className="global-card">
              <div className="global-card__inner-wrapper">
                <ImageUploader
                  uploadImages={uploadImages}
                  setUploadImages={setUploadImages}
                />
              </div>
            </div>
            <div className="information-card">
              <p>
                If this is regarding an appliance, Water Heater, or HVAC
                system please include picture of manufacturer label.
              </p>
            </div>
          </div>
          <div className="footer-action">
            <div className="container d-flex gap-3">
              <Button
                type="button"
                color="secondary"
                block
                disabled={isUploading()}
                onClick={() => navigate('/description')}
              >
                Back
              </Button>
              <Button
                type="button"
                color="primary"
                className="btn-primary-bh"
                block
                disabled={isUploading()}
                onClick={handleNextStepClickHandler}
              >
                Continue
              </Button>
            </div>
          </div>
        </Form>
      </FormProvider>
      {showImageUploadConfirmationModal ? (
        <CommonConfirmationModal
          modalHeading="Upload Photos to Continue"
          modalContent={(
            <p>
              If possible please upload photos as they allow our team to better
              understand the situation to provide faster resolution.
            </p>
          )}
          modalWrapperClassName="bh-confirmation-modal"
          cancelButtonText="Upload Photos"
          successButtonText="Continue Anyway"
          cancelButtonClassName="btn-primary-bh"
          onCancelHandler={() => setShowImageUploadConfirmationModal(false)}
          closeModal={() => setShowImageUploadConfirmationModal(false)}
          onSuccessHandler={onConfirmClickHandler}
        />
      ) : (
        ''
      )}
    </>
  );
};

export default UploadMedia;
