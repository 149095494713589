import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'reactstrap';
import { useDispatch } from 'react-redux';

import { resetFormData } from '../../reducers/tenant-job-entry';
import { SubmittedIcon } from '../../../assets/images/svg-icons';

const WorkOrderSuccess = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(resetFormData());
  }, [dispatch]);

  return (
    <div className="info-form">
      <div className="bh-work-order-submit">
        <div className="bh-work-order-submit-icon">
          <SubmittedIcon />
        </div>
        <div className="bh-work-order-submit-contents">
          <h3>Maintenance Request Submitted!</h3>
          <h5>What Happens Now?</h5>
          <p>
            If additional information is needed our maintenance
            coordination team will reach out within an hour, otherwise
            you will receive a text message shortly to confirm the date/time we have this scheduled.
          </p>
        </div>
        <Button
          type="submit"
          color="secondary"
          block
          onClick={() => navigate('/work-order-info')}
        >
          Back To Main Menu
        </Button>
      </div>
    </div>
  );
};

export default WorkOrderSuccess;
