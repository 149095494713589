import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import Select from 'react-select';

const SelectField = (props) => {
  const {
    control,
    formState: { errors },
  } = useFormContext();
  const {
    name: propsName,
    className = '',
    classNamePrefix = '',
    shouldUnregister,
    isLoading,
    isDisabled,
    ...inputProps
  } = props;

  return (
    <>
      <Controller
        name={propsName}
        control={control}
        shouldUnregister={shouldUnregister}
        render={({ field: { onChange, onBlur, value } }) => (
          <Select
            className={className}
            classNamePrefix={classNamePrefix}
            name={propsName}
            onBlur={onBlur}
            onChange={(event) => {
              onChange(event);
            }}
            {...inputProps}
            isLoading={isLoading}
            isDisabled={isDisabled}
            value={value}
          />
        )}
      />
      <ErrorMessage
        errors={errors}
        name={propsName}
        render={({ message }) => (
          <small className="text-danger">{message}</small>
        )}
      />
    </>
  );
};

export default SelectField;
