import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import BridgeHomesLayout from './index';
import { getLocalStorage } from '../../../helpers/utils';

const PortalLayout = () => {
  useEffect(() => {
    const token = getLocalStorage('userAccessToken');
    if (!token && !['/property-info', '/add-new-property']?.includes(window?.location.pathname)) {
      window.location.pathname = '/property-info';
    }
  }, []);

  return (
    <BridgeHomesLayout>
      <Outlet />
    </BridgeHomesLayout>
  );
};

export default PortalLayout;
