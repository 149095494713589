import React, {
  useState,
  useEffect,
  useCallback,
} from 'react';
import { isEmpty } from 'lodash';
import { Form, Button, Spinner } from 'reactstrap';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import Axios from 'axios';
import { Autocomplete, useLoadScript } from '@react-google-maps/api';
import Loader from '../../shared/loader';
import LoaderRound from '../../shared/loader-round';
import SelectField from '../../shared/form-field/select-field';
import InputField from '../../shared/form-field/input-field';

import catchHandler from '../../../helpers/catchHandler';
import { getFirstNames, validatePropertyAddress } from '../../services/services';
import { getLocalStorage, setLocalStorage } from '../../../helpers/utils';
import { setLoginFormData, setResidentInfo } from '../../reducers/tenant-job-entry';

const libraries = ['places'];

const filed = ['name', 'place_id'];

const API_KEY = process.env.REACT_APP_GOOGLE_MAP_API_KEY;

const PropertyInfo = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const lookUpData = useSelector((state) => state?.tenantJobEntry?.lookup_data);

  const [validatePropertyAddressLoading, setValidatePropertyAddressLoading] = useState(false);
  const [autocomplete, setAutocomplete] = useState(null);
  const [isPlaceDataFetching, setIsPlaceDataFetching] = useState(false);
  const [isFirstNameFetching, setIsFirstNameFetching] = useState(false);
  const [firstNameOptions, setFirstNameOptions] = useState([]);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: API_KEY,
    libraries,
  });

  const onLoad = (autoCompleteInfo) => {
    setAutocomplete(autoCompleteInfo);
  };

  const schema = yup.object().shape({
    address: yup.string().trim().required('Property address is required').test(
      'is-valid',
      'Address should contain street, city, state and zip code',
      (_, { options }) => !!(options?.parent?.street_address?.trim()
        && options?.parent?.city?.trim()
        && options?.parent?.state?.trim()
        && options?.parent?.zip_code?.trim()),
    ),
    first_name: yup.object().nullable().required('First name is required'),
    last_name: yup
      .string().trim().when('google_place_id', {
        is: (value) => value,
        then: (currentSchema) => currentSchema.required('Last name is required.'),
      }),
  });

  const methods = useForm({
    mode: 'all',
    defaultValues: ({
      street_address: '',
      city: '',
      state: '',
      zip_code: '',
      address: '',
      last_name: '',
      google_place_id: '',
    }),
    resolver: yupResolver(schema),
  });

  const {
    handleSubmit,
    setError,
    setValue,
    watch,
    reset,
    getValues,
    trigger,
    formState: { isValid },
    clearErrors,
  } = methods;

  const onFormSubmitCatchHandler = useCallback((err) => {
    if (err?.data?.errors?.redirect_to_add_new_property_page) {
      dispatch(setLoginFormData({
        formValues: getValues(),
        errors: err?.data?.errors,
      }));
      navigate('/add-new-property');
    } else {
      catchHandler(err, setError, true);
    }
  }, [dispatch, getValues, navigate, setError]);

  const onSubmit = useCallback((formData) => {
    setValidatePropertyAddressLoading(true);
    const payload = {
      google_place_id: formData?.google_place_id || '',
      last_name: formData?.last_name,
      address: formData?.address,
      street_address: formData?.street_address,
      city: formData?.city,
      state: formData?.state,
      zip_code: formData?.zip_code,
      customer_id: lookUpData?.customer_id,
      first_name: formData?.first_name?.label,
    };
    validatePropertyAddress(payload).then((res) => {
      if (res?.token) {
        setLocalStorage('userAccessToken', res?.token);
      }
      if (window?.smartlook) {
        window.smartlook('identify', res?.resident?.resident_id, {
          name: res?.resident?.tenant_name,
          email: res?.resident?.email,
        });
      }
      dispatch(setLoginFormData({
        formValues: {},
        errors: {},
      }));
      dispatch(setResidentInfo(res?.resident));
      navigate('/work-order-info');
      setValidatePropertyAddressLoading(false);
    }).catch((err) => {
      onFormSubmitCatchHandler(err, formData);
      setValidatePropertyAddressLoading(false);
    });
  }, [dispatch, lookUpData?.customer_id, navigate, onFormSubmitCatchHandler]);

  const propertyAddressFormValues = watch();

  const handlePlaceChanged = useCallback(() => {
    if (autocomplete) {
      const place = autocomplete?.getPlace();
      setIsPlaceDataFetching(true);
      Axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?key=${API_KEY}&address=${place?.name}`,
      )
        .then((res) => {
          if (res?.status === 200) {
            const result = res?.data?.results[0];

            const addressComponents = result?.address_components;

            // Finding the required details from address components
            const address = {};
            addressComponents?.forEach((component) => {
              if (component?.types?.includes('postal_code')) {
                const postalCode = component?.long_name;
                if (postalCode) {
                  address.zip_code = postalCode;
                }
              }
              if (component?.types?.includes('route')) {
                address.route = component?.short_name;
              }
              if (component?.types?.includes('street_number')) {
                address.street_number = component?.long_name;
              }
              if (component?.types?.includes('administrative_area_level_1')) {
                if (!isEmpty(component?.short_name)) {
                  address.state = component?.short_name;
                }
              }
              if (component?.types?.includes('locality')) {
                address.city = component?.long_name;
              }
              if (component?.types?.includes('country')) {
                address.country = component?.long_name;
              }
            });
            address.street_address = `${address?.street_number || ''} ${address?.route || ''
            }`;
            reset((prev) => ({
              ...prev,
              street_address: address?.street_address,
              city: address?.city,
              state: address?.state,
              zip_code: address?.zip_code,
              google_place_id: place?.place_id,
              address: place?.name,
            }));
            trigger('address');
            isPlaceDataFetching(false);
          } else {
            toast('Something Went Wrong', { theme: 'colored', type: 'error' });
          }
        }).catch(() => {
          setIsPlaceDataFetching(false);
        });
    }
  }, [autocomplete, isPlaceDataFetching, reset, trigger]);

  const checkButtonIsDisabled = () => validatePropertyAddressLoading
    || !(propertyAddressFormValues?.address?.trim()
      && propertyAddressFormValues?.google_place_id);

  const onInputFiledChangeHandler = useCallback((e) => {
    if (e?.target?.value?.trim()?.length > 0) {
      setIsFirstNameFetching(true);
      const formValues = getValues();
      getFirstNames({
        google_place_id: formValues?.google_place_id || '',
        last_name: e?.target?.value,
        address: formValues?.address,
        street_address: formValues?.street_address,
        city: formValues?.city,
        state: formValues?.state,
        zip_code: formValues?.zip_code,
        customer_id: lookUpData?.customer_id,
      }).then((res) => {
        if (Array.isArray(res)) {
          const options = res?.map((item) => ({
            value: item?.resident_id,
            label: item?.first_name,
          }));
          if (options?.length === 1) {
            setValue('first_name', options[0], { shouldValidate: true });
          }
          setFirstNameOptions(options);
        }
        setIsFirstNameFetching(false);
      }).catch((err) => {
        onFormSubmitCatchHandler(err);
        setIsFirstNameFetching(false);
      });
    }
  }, [getValues, lookUpData?.customer_id, onFormSubmitCatchHandler, setValue]);

  // const debouncedInputFiledChangeHandler = useCallback(debounce(
  //   onInputFiledChangeHandler,
  //   500,
  // ), []);

  useEffect(() => {
    if (isEmpty(propertyAddressFormValues?.address?.trim())) {
      setValue('google_place_id', '');
    }
  }, [propertyAddressFormValues?.address, setValue]);

  useEffect(() => {
    const token = getLocalStorage('userAccessToken');
    if (token && ['/property-info', '/add-new-property']?.includes(window?.location.pathname)) {
      window.location.pathname = '/work-order-info';
    }
  }, []);

  if (isLoaded) {
    return (
      <FormProvider {...methods}>
        <Form className="info-form" onSubmit={handleSubmit(onSubmit)}>
          <div className="content-wrapper">
            <h2>Property Address</h2>
            <p>
              What&apos;s the address that needs maintenance?
            </p>
            <div className="resident-info-data resident-info-data-property">
              <div className="bh-form-element">
                <Autocomplete
                  enablePoweredByContainer={false}
                  onLoad={onLoad}
                  style={{ width: '10%' }}
                  onPlaceChanged={handlePlaceChanged}
                  fields={filed}
                  options={{
                    types: ['geocode'],
                    componentRestrictions: { country: 'us' }, // Restrict to United States
                  }}
                >
                  <InputField
                    name="address"
                    className=""
                    placeholder="Enter Property Address"
                  />
                </Autocomplete>
                {propertyAddressFormValues?.address?.trim()
                  && propertyAddressFormValues?.google_place_id
                  ? (
                    <>
                      <div className="last-name-wrapper">
                        <InputField
                          name="last_name"
                          className="last-name-field"
                          placeholder="Last Name"
                          onBlur={onInputFiledChangeHandler}
                          customHandleChange={() => {
                            setFirstNameOptions([]);
                            clearErrors('first_name');
                            setValue('first_name', null);
                          }}
                          onKeyDown={(event) => {
                            if (event?.key === 'Enter') {
                              event?.target?.blur();
                            }
                          }}
                        />
                        {isFirstNameFetching ? (
                          <div className="last-name-spinner-wrapper">
                            <Spinner
                              className="last-name-loading-spinner"
                              color="primary"
                              size="sm"
                            />
                          </div>
                        ) : ''}
                      </div>
                      {firstNameOptions?.length > 0 ? (
                        <SelectField
                          name="first_name"
                          placeholder="First Name"
                          className="form-control bh-select bh-select--first_name"
                          classNamePrefix="bh-prefix"
                          options={firstNameOptions}
                          isLoading={isFirstNameFetching}
                          isDisabled={isFirstNameFetching}
                          components={{
                            IndicatorSeparator: () => null,
                          }}
                        />
                      ) : ''}
                    </>
                  ) : ''}
              </div>
              <div className="sub-contet-wrapper text-center">
                <p>Can&apos;t Find Your Address?</p>
                <Button
                  type="button"
                  className="support-line"
                  onClick={() => navigate('/add-new-property')}
                >
                  Enter Manually
                </Button>
              </div>
            </div>
          </div>
          <div className="footer-action">
            <div className="container d-flex gap-3">
              <Button
                type="submit"
                color="primary"
                className="btn-primary-bh"
                block
                disabled={checkButtonIsDisabled() || isPlaceDataFetching || !isValid}
              >
                {validatePropertyAddressLoading ? (
                  <LoaderRound />
                ) : 'Continue'}
              </Button>
            </div>
          </div>
        </Form>
      </FormProvider>
    );
  }

  return <Loader />;
};

export default PropertyInfo;
