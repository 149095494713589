import React, { useEffect, useState } from 'react';
import {
  isEmpty, omit, uniqueId,
} from 'lodash';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';

import { useForm } from 'react-hook-form';
import { CloseSolidIcon, FileUploadIcon } from '../../assets/images/svg-icons';
import { setRemoveImage, setUploadedImage } from '../reducers/tenant-job-entry';
import { uploadMedia } from '../services/services';
import ConfirmationModal from './confirmation-modal';
import FileUploader from './form-field/file-uploader';
import catchHandler from '../../helpers/catchHandler';

const ImageUploader = ({ uploadImages, setUploadImages }) => {
  const uploadedImage = useSelector((state) => state.tenantJobEntry?.job_medias);
  const dispatch = useDispatch();
  const [confirmRemoveImage, setConfirmRemoveImage] = useState('');

  const methods = useForm({ defaultValues: {} });
  const { setError, formState: { errors }, clearErrors } = methods;

  const handleOnchange = (images) => {
    clearErrors();
    if (!isEmpty(images)) {
      const uploadFiles = {};
      const unSupportedImage = {};
      Object.keys(images)?.forEach((key) => {
        const id = uniqueId('file_');
        const imgObj = {
          status: 'not-uploaded',
          isLoading: true,
          imageObj: images[key],
        };
        if (images[key].type?.includes('image')) {
          uploadFiles[id] = imgObj;
        } else {
          unSupportedImage[id] = imgObj;
        }
      });

      const willUpload = [...uploadedImage, ...images];

      if (willUpload?.length <= 8) {
        if (!isEmpty(unSupportedImage)) {
          toast('The job media upload must be a file of type: jpg, jpeg, png.', { type: 'error' });
        }
        setUploadImages((prev) => ({ ...prev, ...uploadFiles }));
        const currentImageList = { ...uploadFiles };
        Object.keys(currentImageList)?.forEach((key) => {
          const obj = { ...uploadFiles[key] };
          const file = obj?.imageObj;
          const formData = new FormData();
          formData.append('job_media_upload', file);
          uploadMedia(formData).then((res) => {
            setUploadImages((prev) => omit(prev, key));
            dispatch(setUploadedImage(res));
          }).catch((err) => {
            setUploadImages((prev) => omit(prev, key));
            catchHandler(err, setError);
          });
        });
      } else {
        toast('You can only upload upto 8 files', { type: 'error' });
      }
    }
    document.getElementById('image-uploader').value = '';
  };

  const handleRemoveMedia = (id) => {
    dispatch(setRemoveImage(id));
    setConfirmRemoveImage('');
  };

  useEffect(() => {
    if (typeof errors?.general_errors?.message === 'object' && !isEmpty(errors?.general_errors?.message)) {
      toast(errors?.general_errors?.message[0], { type: 'error' });
    } else if (errors?.general_errors?.message) {
      toast(errors?.general_errors?.message, { type: 'error' });
    }
  }, [errors?.general_errors?.message]);

  const isUploading = () => Object.keys(uploadImages)?.some((key) => uploadImages[key]?.isLoading);

  return (
    <>
      <div className="upload-image">
        {uploadedImage?.map((item) => (
          <div className="image-uploaded" key={item?.job_media_id}>
            <img src={item?.job_media_url} alt="job-media" />
            <Link
              to="#"
              className="delete-img"
              onClick={() => setConfirmRemoveImage(item?.job_media_id)}
            >
              <CloseSolidIcon />
            </Link>
          </div>
        ))}
        {Object.keys(uploadImages)?.map((key) => {
          const data = uploadImages[key];
          return data?.isLoading ? (
            <div className="image-uploaded" key={key}>
              <div className="image-loader">
                <div className="loader">Loading...</div>
              </div>
            </div>
          ) : '';
        })}
        {(([...uploadedImage, ...(Object.keys(uploadImages)?.map(
          (item) => uploadImages[item],
        ) || [])]?.length < 8) && !isUploading()) ? (
          <div className="upload-image__input">
            <FileUploader
              name="images"
              id="image-uploader"
              type="file"
              multiple
              accept="image/*"
              handleOnchange={handleOnchange}
              hideFormError
              clearError
            />
            <div>
              <FileUploadIcon />
              <p>Upload</p>
            </div>
          </div>
          ) : ''}
      </div>
      {confirmRemoveImage ? (
        <ConfirmationModal
          closeModal={() => setConfirmRemoveImage('')}
          confirmBtnText="Yes, Proceed"
          confirmationText="Are you sure want to delete this image ?"
          handleConfirm={() => handleRemoveMedia(confirmRemoveImage)}
        />
      ) : ''}
    </>
  );
};

export default ImageUploader;
