import React, { useCallback, useEffect, useState } from 'react';
import {
  Form, Button, Row, Col, FormGroup, Label,
} from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router-dom';
import { FormProvider, useForm } from 'react-hook-form';
import { isEmpty } from 'lodash';
import { toast } from 'react-toastify';

import InputField from '../../shared/form-field/input-field';
import SelectField from '../../shared/form-field/select-field';
import CustomNumberField from '../../shared/form-field/custom-number-field';
import LoaderRound from '../../shared/loader-round';

import { setLoginFormData } from '../../reducers/tenant-job-entry';
import { createPropertyInfo } from '../../services/services';
import { getLocalStorage, setLocalStorage } from '../../../helpers/utils';
import catchHandler from '../../../helpers/catchHandler';

const AddNewPropertyInfo = () => {
  const dispatch = useDispatch();
  const [propertyInfoFormSubmitBtnLoading, setPropertyInfoFormSubmitBtnLoading] = useState(false);

  const states = useSelector((state) => state?.tenantJobEntry?.states);
  const lookUpData = useSelector((state) => state?.tenantJobEntry?.lookup_data);
  const loginFormData = useSelector((state) => state?.tenantJobEntry?.loginFormData);

  const schema = yup.object().shape({
    first_name: yup.string().trim().required('First name is required'),
    last_name: yup.string().trim().required('Last name is required'),
    street_address: yup.string().trim().required('Street address is required'),
    email: yup.string().trim().email('Enter a valid email').required('Email is required'),
    mobile_phone: yup.string().trim().required('Phone number is required').min(10, 'Enter a valid 10 digit number'),
    zip_code: yup.string().trim().required('Zip code is required').min(5, 'Enter a valid zip code'),
    city: yup.string().trim().required('City is required'),
    state: yup.object().nullable().required('State is required'),
  });

  const navigate = useNavigate();
  const methods = useForm({
    mode: 'all',
    defaultValues: ({
      first_name: '',
      last_name: '',
      email: '',
      mobile_phone: '',
      street_address: '',
      zip_code: '',
      apt_suite_number: '',
      city: '',
      state: null,
    }),
    resolver: yupResolver(schema),
  });

  const {
    reset,
    setError,
    handleSubmit,
  } = methods;

  const clearLoginFormErrors = useCallback(() => {
    dispatch(setLoginFormData({
      formValues: {},
      errors: {},
    }));
  }, [dispatch]);

  const onSubmit = useCallback((formData) => {
    setPropertyInfoFormSubmitBtnLoading(true);
    const payload = {
      ...formData,
      customer_id: lookUpData?.customer_id,
      state: formData?.state?.state_code,
    };
    createPropertyInfo(payload).then((res) => {
      if (res?.token) {
        setLocalStorage('userAccessToken', res?.token);
        navigate('/service-category');
      }
      setPropertyInfoFormSubmitBtnLoading(false);
    }).catch((errors) => {
      if (!isEmpty(errors?.data?.errors?.general)
      && Array.isArray(errors?.data?.errors?.general)) {
        errors?.data?.errors?.general?.forEach((error) => {
          toast(error, { type: 'error' });
        });
      } else {
        catchHandler(errors, setError, true);
      }
      setPropertyInfoFormSubmitBtnLoading(false);
    }).finally(() => {
      clearLoginFormErrors();
    });
  }, [clearLoginFormErrors, lookUpData?.customer_id, navigate, setError]);

  const onBackButtonClickHandler = useCallback(() => {
    navigate('/property-info');
    if (loginFormData?.formValues?.last_name || loginFormData?.errors?.last_name) {
      clearLoginFormErrors();
    }
  }, [
    clearLoginFormErrors,
    loginFormData?.errors?.last_name,
    loginFormData?.formValues?.last_name,
    navigate,
  ]);

  useEffect(() => {
    if (loginFormData?.formValues) {
      reset((prevFormValues) => ({
        ...(prevFormValues || {}),
        last_name: loginFormData?.formValues?.last_name || '',
        street_address: loginFormData?.formValues?.street_address || '',
        zip_code: loginFormData?.formValues?.zip_code || '',
        city: loginFormData?.formValues?.city || '',
        state: states?.find(
          (state) => state?.state_code === loginFormData?.formValues?.state,
        ) || null,
      }));
    }
  }, [loginFormData?.formValues, reset, states]);

  useEffect(() => {
    if (loginFormData?.errors?.last_name) {
      toast(loginFormData?.errors?.last_name, { type: 'error' });
    }
  }, [loginFormData?.errors?.last_name]);

  useEffect(() => {
    const token = getLocalStorage('userAccessToken');
    if (token && ['/property-info', '/add-new-property']?.includes(window?.location.pathname)) {
      window.location.pathname = '/work-order-info';
    }
  }, []);

  return (
    <FormProvider {...methods}>
      <Form className="info-form" onSubmit={handleSubmit(onSubmit)}>
        <div className="content-wrapper">
          <h2>Enter Your Information</h2>
          <p>
            Enter your personal and property information
          </p>
          <div className="resident-info-data property-details">
            <div className="bh-page-data">
              <Row>
                <Col>
                  <h2>Personal Info</h2>
                </Col>
              </Row>
              <Row>
                <Col xs="6">
                  <FormGroup>
                    <Label for="first_name">
                      First Name
                    </Label>
                    <InputField name="first_name" />
                  </FormGroup>
                </Col>
                <Col xs="6">
                  <FormGroup>
                    <Label for="last_name">
                      Last Name
                    </Label>
                    <InputField name="last_name" />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs="12">
                  <FormGroup>
                    <Label for="email">
                      Email Address
                    </Label>
                    <InputField name="email" />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs="12">
                  <FormGroup>
                    <Label for="mobile_phone">
                      Phone Number
                    </Label>
                    <CustomNumberField
                      name="mobile_phone"
                      placeholder="(_ _ _) _ _ _ - _ _ _ _"
                      format="(# # #) # # # - # # # #"
                      type="text"
                      className="form-control"
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <h2 className="custom-m">Property Info</h2>
                </Col>
              </Row>
              <Row>
                <Col xs="12">
                  <FormGroup>
                    <Label for="street_address">
                      Street Address
                    </Label>
                    <InputField name="street_address" />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs="6">
                  <FormGroup>
                    <Label for="zip_code">
                      Zip Code
                    </Label>
                    <CustomNumberField
                      name="zip_code"
                      placeholder="_ _ _ _ _"
                      format="#####"
                      type="text"
                      className="form-control"
                    />
                  </FormGroup>
                </Col>
                <Col xs="6">
                  <FormGroup>
                    <Label for="apt_suite_number">
                      Apt, suite, etc.
                    </Label>
                    <InputField name="apt_suite_number" />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col xs="6">
                  <FormGroup>
                    <Label for="city">
                      City
                    </Label>
                    <InputField name="city" />
                  </FormGroup>
                </Col>
                <Col xs="6">
                  <FormGroup>
                    <Label for="state">
                      State
                    </Label>
                    <SelectField
                      menuPlacement="auto"
                      name="state"
                      className="form-control bh-select"
                      classNamePrefix="bh-prefix"
                      placeholder="Select"
                      options={states}
                      components={{
                        IndicatorSeparator: () => null,
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </div>
          </div>
        </div>
        <div className="footer-action">
          <div className="container d-flex gap-3">
            <Button
              block
              type="button"
              color="secondary"
              onClick={onBackButtonClickHandler}
            >
              Back
            </Button>
            <Button
              type="submit"
              color="primary"
              className="btn-primary-bh"
              block
              disabled={propertyInfoFormSubmitBtnLoading}
            >
              {propertyInfoFormSubmitBtnLoading ? (
                <LoaderRound />
              ) : 'Continue'}
            </Button>
          </div>
        </div>
      </Form>
    </FormProvider>
  );
};

export default AddNewPropertyInfo;
