import React from 'react';
import { Button } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import moment from 'moment';

const WorkOrderListing = () => {
  const navigate = useNavigate();
  const openWorkOrders = useSelector((state) => state?.tenantJobEntry?.open_work_orders);
  return (
    <div className="info-form">
      <div className="content-wrapper">
        <h2>Open Work Orders</h2>
        <p>
          This is a list of open maintenance requests
        </p>
        <div className="resident-info-data work-order-listing">
          {(openWorkOrders || [])?.map((workOrder) => (
            <div className="bh-work-order" key={workOrder?.job_id}>
              <div className="bh-work-order-card">
                <div className="top-section">
                  <div className="service-category">{workOrder?.service_category_label}</div>
                  <div className="service-status">{workOrder?.job_status_label}</div>
                </div>
                <div className="bottom-section">
                  <p>
                    Last update:
                    {' '}
                    {moment?.utc(workOrder?.last_updated_at)?.fromNow()}
                  </p>
                </div>
              </div>
            </div>
          ))}
          <Button
            color="primary"
            block
            outline
            className="new-request-btn"
            onClick={() => navigate('/service-category')}
          >
            Submit New Maintenance Request
          </Button>
        </div>
      </div>
      <div className="footer-action">
        <div className="container d-flex gap-3">
          <Button
            color="secondary"
            block
            onClick={() => navigate('/work-order-info')}
          >
            Back
          </Button>
        </div>
      </div>
    </div>
  );
};

export default WorkOrderListing;
