import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';

import Header from './header';
import { setFetchingLookupData, setLookupData } from '../../reducers/tenant-job-entry';
import { getLookUpData } from '../../services/services';
import 'react-toastify/dist/ReactToastify.css';
import { getLocalStorage, setLocalStorage } from '../../../helpers/utils';

const BridgeHomesLayout = ({ children }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setFetchingLookupData(true));
    const isDevLocal = window.location.hostname.includes('localhost');
    const domain = isDevLocal ? 'bridgehomes' : window.location.hostname.split('.')[0];
    getLookUpData({ domain })
      .then((res) => {
        dispatch(setLookupData(res));
        dispatch(setFetchingLookupData(false));
      })
      .catch(() => {
        dispatch(setFetchingLookupData(false));
        toast('Something went wrong.', { type: 'error' });
      });
  }, [dispatch]);

  const getWrapperClassName = useCallback(() => {
    if (window.location.pathname === '/work-order-info' || window.location.pathname === '/success') {
      return 'no-footer';
    } return '';
  }, []);

  useEffect(() => {
    if (process.env.REACT_APP_SMART_LOOK_ID) {
      const script = document.createElement('script');
      script.innerHTML = `
        window.smartlook||(function(d) {
          var o=smartlook=function(){ o.api.push(arguments)},h=d.getElementsByTagName('head')[0];
          var c=d.createElement('script');o.api=new Array();c.async=true;c.type='text/javascript';
          c.charset='utf-8';c.src='https://web-sdk.smartlook.com/recorder.js';h.appendChild(c);
        })(document);
        smartlook('init', '${process.env.REACT_APP_SMART_LOOK_ID}', { region: 'eu' });
        smartlook('record', {
          numbers: true,
          forms: true,
          ips: true,
        });
      `;
      document.head.appendChild(script);
    }
  }, []);

  useEffect(() => {
    const shouldNavToMenu = getLocalStorage('shouldNavToMenu');
    if (shouldNavToMenu && !['/property-info', '/add-new-property']?.includes(window?.location.pathname)) {
      setLocalStorage('shouldNavToMenu', false);
      const URL = `${window.location.origin}/work-order-info`;
      window.location.replace(URL);
    }
  }, []);

  useEffect(() => {
    const handleBeforeUnload = () => {
      setLocalStorage('shouldNavToMenu', true);
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  return (
    <div className="bh">
      <Header />
      <div className={`data-wrapper ${getWrapperClassName()}`}>
        <div className="container">
          {children}
        </div>
      </div>
      <ToastContainer
        position="bottom-right"
        theme="colored"
      />
    </div>
  );
};

export default BridgeHomesLayout;
