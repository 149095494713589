import React, { useEffect, useState } from 'react';
import { Button } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { isEmpty } from 'lodash';

import { getServiceCategories } from '../../services/services';
import Loader from '../../shared/loader';
import { setServiceCategory } from '../../reducers/tenant-job-entry';
import {
  HandyManIcon,
  PlumbingIcon,
  ApplianceIcon,
  HeatingCoolingIcon,
  ElectricalIcon,
  HotWaterHeaterIcon,
  DrainCleaningIcon,
  OtherIcon,
} from '../../../assets/images/svg-icons';
import MoreDetailsInfoModal from '../../troubleshooting/more-details-info-modal';

const ServiceCategory = () => {
  const [serviceCategories, setServiceCategories] = useState([]);
  const [loading, setLoading] = useState(false);

  const [showServiceCategoryInfoModal, setShowServiceCategoryInfoModal] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleSelectJob = (service) => {
    dispatch(setServiceCategory(service));
    navigate('/description');
  };

  useEffect(() => {
    setLoading(true);
    getServiceCategories().then((res) => {
      const categories = res?.service_categories;
      const notContainsOther = categories?.filter((item) => item?.slug !== 'other');
      const other = categories?.filter((item) => item?.slug === 'other');
      const sortCategories = [...notContainsOther, ...other];
      setServiceCategories(sortCategories);
      setLoading(false);
    }).catch((err) => {
      setLoading(false);
    });
  }, []);

  const iconComponents = {
    handyman: <HandyManIcon />,
    plumbing: <PlumbingIcon />,
    appliances: <ApplianceIcon />,
    hvac: <HeatingCoolingIcon />,
    electrical: <ElectricalIcon />,
    'hot-water-heater': <HotWaterHeaterIcon />,
    'drain-cleaning': <DrainCleaningIcon />,
    unknown: <OtherIcon />,
  };

  return (
    <>
      <div className="content-wrapper">
        <div className="step">Step 1/5</div>
        <h2>Choose Service Category</h2>
        <p>What category does your current issue fall under?</p>
        {loading ? <Loader /> : (
          <div className="troubleshoot">
            {!isEmpty(serviceCategories) ? (
              <>
                <div className="service-outer">
                  {serviceCategories?.map((item) => (
                    <Button
                      className="service-card"
                      onClick={() => handleSelectJob(item)}
                      key={item?.service_category_id}
                    >
                      {iconComponents[item?.slug] || ''}
                      {item?.label}
                    </Button>
                  ))}
                </div>
                <div className="troubleshoot-details">
                  <p>Not sure about your issue category?</p>
                  <Button onClick={() => setShowServiceCategoryInfoModal(true)}>
                    See More Details
                  </Button>
                </div>
              </>
            ) : 'No Service Category Found'}
          </div>
        )}
      </div>
      <div className="footer-action">
        <div className="container d-flex gap-3">
          <Button
            block
            type="button"
            color="secondary"
            onClick={() => navigate('/work-order-info')}
          >
            Back
          </Button>
        </div>
      </div>
      {showServiceCategoryInfoModal ? (
        <MoreDetailsInfoModal
          wrapperClassName="more-service-modal bh-common-modal"
          closeModal={() => setShowServiceCategoryInfoModal(false)}
          serviceCategories={serviceCategories}
        />
      ) : (
        ''
      )}
    </>
  );
};

export default ServiceCategory;
