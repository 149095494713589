/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isAuthenticated: false,
  phone: '',
  tenant_details: '',
  service_category: '',
  description: '',
  job_medias: [],
  is_emergency: false,
  job_schedules: {},
  property_access: '',
  specific_access_note: '',
  door_code: '',
  property_access_options: {},
  work_order_status: '',
  summaryFormErrors: {},
  lookup_data: {},
  fetching_lookup_data: false,
  states: [],
  open_work_orders: [],
  residentInfo: {},
  loginFormData: {
    formValues: {},
    errors: {},
  },
};
export const tenantJobEntrySlice = createSlice({
  name: 'tenantJobEntry',
  initialState,
  reducers: {
    setIsAuthenticated: (state, action) => {
      state.isAuthenticated = action.payload;
    },
    setPhoneNumber: (state, action) => {
      state.phone = action.payload;
    },
    setSelectedTenant: (state, action) => {
      state.tenant_details = action.payload;
    },
    setServiceCategory: (state, action) => {
      state.service_category = action.payload;
    },
    setDescription: (state, action) => {
      state.description = action.payload;
    },
    setUploadedImage: (state, action) => {
      const currentJobMedia = [...state.job_medias, action.payload];
      state.job_medias = currentJobMedia;
    },
    setRemoveImage: (state, action) => {
      const newJobMedia = state.job_medias?.filter((item) => item?.job_media_id !== action.payload);
      state.job_medias = newJobMedia;
    },
    setJobEmergency: (state, action) => {
      state.is_emergency = action.payload;
    },
    setAvailabilities: (state, action) => {
      state.job_schedules = action.payload;
    },
    setPropertyAccessDetails: (state, action) => {
      const { propertyAccess, specificAccessNote, doorCode } = action.payload;
      state.property_access = propertyAccess;
      state.specific_access_note = specificAccessNote;
      state.door_code = doorCode;
    },
    setPropertyAccessAllOptions: (state, action) => {
      state.property_access_options = action.payload;
    },
    setWorkOrderStatus: (state, action) => {
      state.work_order_status = action.payload;
    },
    setSummaryFormErrors: (state, action) => {
      state.summaryFormErrors = action.payload;
    },
    resetFormData: (state) => {
      state.phone = '';
      state.tenant_details = '';
      state.service_category = '';
      state.description = '';
      state.job_medias = [];
      state.is_emergency = false;
      state.job_schedules = {};
      state.property_access = '';
      state.specific_access_note = '';
      state.door_code = '';
      state.property_access_options = {};
      state.summaryFormErrors = {};
      state.work_order_status = '';
      state.open_work_orders = [];
    },
    setLookupData: (state, action) => {
      state.lookup_data = action.payload;
      const stateOptions = (action.payload.states || [])?.map((data) => ({
        label: data?.state_name,
        value: data?.state_id,
        state_code: data?.state_code,
      }));
      state.states = stateOptions;
    },
    setFetchingLookupData: (state, action) => {
      state.fetching_lookup_data = action.payload;
    },
    setOpenWorkOrders: (state, action) => {
      state.open_work_orders = action.payload;
    },
    setResidentInfo: (state, action) => {
      state.residentInfo = action.payload;
    },
    reset: (state) => ({
      ...(initialState || {}),
      lookup_data: state?.lookup_data,
      states: state?.states,
    }),
    setLoginFormData: (state, action) => {
      state.loginFormData = action?.payload;
    },
  },
});

export const {
  setPhoneNumber,
  setSelectedTenant,
  setServiceCategory,
  setDescription,
  setUploadedImage,
  setJobEmergency,
  setRemoveImage,
  setAvailabilities,
  setPropertyAccessDetails,
  setPropertyAccessAllOptions,
  setWorkOrderStatus,
  resetFormData,
  setSummaryFormErrors,
  setLookupData,
  setFetchingLookupData,
  setIsAuthenticated,
  setOpenWorkOrders,
  setResidentInfo,
  reset,
  setLoginFormData,
} = tenantJobEntrySlice.actions;

export default tenantJobEntrySlice.reducer;
