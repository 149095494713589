import { request } from '../../helpers/api';

export const phoneNumberVerification = (param) => (
  request({
    method: 'POST',
    contentType: 'json',
    url: '/phone/verify',
    param,
    isAuthenticated: false,
  })
);

export const verifyOtp = (param) => (
  request({
    method: 'POST',
    url: '/phone/verify/code/submit',
    param,
    contentType: 'json',
    isAuthenticated: false,
  })
);

export const getLocations = () => (
  request({
    method: 'GET',
    url: '/get-locations',
  })
);

export const getServiceCategories = () => (
  request({
    method: 'GET',
    url: '/service-categories',
  })
);

export const uploadMedia = (param) => (
  request({
    method: 'POST',
    url: '/upload-job-media',
    param,
  })
);

export const getPropertiesAccessDetails = () => (
  request({
    method: 'GET',
    url: '/get-property-access-details',
  })
);

export const createWorkOrder = (param) => (
  request({
    method: 'POST',
    url: '/create-work-order',
    param,
    contentType: 'json',
  })
);

export const getLookUpData = (param) => (
  request({
    method: 'GET',
    url: '/look-up',
    param,
  })
);

export const createPropertyInfo = (param) => (
  request({
    method: 'POST',
    url: '/create-lookup-resident',
    param,
    contentType: 'json',
  })
);

export const validatePropertyAddress = (param) => (
  request({
    method: 'POST',
    url: '/validate-property-address',
    param,
    contentType: 'json',
  })
);

export const getOpenWorkOrders = (param) => (
  request({
    method: 'GET',
    url: '/view-open-work-orders',
    param,
  })
);

export const getFirstNames = (param) => request(
  {
    method: 'GET',
    url: '/resident-first-name',
    param,
  },
);
