import { yupResolver } from '@hookform/resolvers/yup';
import { isEmpty } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import * as yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  Form,
  FormGroup,
  Label,
} from 'reactstrap';

import { ErrorMessage } from '@hookform/error-message';
import { toast } from 'react-toastify';

import moment from 'moment';
import { getPropertiesAccessDetails, createWorkOrder } from '../../services/services';
import CustomRadioButton from '../../shared/form-field/custom-radio-button';
import InputField from '../../shared/form-field/input-field';
import Loader from '../../shared/loader';

import { setPropertyAccessDetails } from '../../reducers/tenant-job-entry';
import catchHandler from '../../../helpers/catchHandler';
import LoaderRound from '../../shared/loader-round';

const AddPropertyAccessDetails = () => {
  const [propertyAccessOptions, setPropertyAccessOptions] = useState({});
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const [isPropertyAccessDetailsFetching, setIsPropertyAccessDetailsFetching] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const tenantEntryDetails = useSelector((state) => state.tenantJobEntry);

  const schema = yup.object().shape({
    propertyAccess: yup.string().trim().required('select a property access option'),
    doorCode: yup.string().trim().when('propertyAccess', {
      is: 'digital-key-code',
      then: yup.string().trim().required('This is a required field'),
    }),
    specificAccessNote: yup.string().when('propertyAccess', {
      is: 'hidden-key',
      then: yup.string().trim().required('This is a required field'),
    }),
  });

  const buildFormData = useCallback((data) => {
    const formData = {};
    formData.propertyAccess = data?.property_access || Object.keys(propertyAccessOptions)[0];
    formData.specificAccessNote = data?.specific_access_note || '';
    formData.doorCode = data?.door_code || '';
    return formData;
  }, [propertyAccessOptions]);

  const methods = useForm({
    defaultValues: {
      propertyAccess: '',
      doorCode: '',
      specificAccessNote: '',
    },
    resolver: yupResolver(schema),
  });

  const {
    handleSubmit,
    setError,
    reset,
    control,
    clearErrors,
    formState: { errors },
  } = methods;

  const propertyAccessFormData = useWatch({ control, name: 'propertyAccess' });

  const onSubmit = (formData) => {
    setIsFormSubmitting(true);
    const propertyAccessData = {
      ...formData,
      specificAccessNote: formData?.specificAccessNote || '',
    };
    dispatch(setPropertyAccessDetails(propertyAccessData));

    const jobSchedules = Object.keys(tenantEntryDetails?.job_schedules || {})
      ?.filter((item) => !isEmpty(tenantEntryDetails?.job_schedules?.[item]))
      ?.map((objKey) => {
        const data = tenantEntryDetails?.job_schedules[objKey];
        return {
          ...data,
          date: data?.date ? moment(data?.date).format('YYYY-MM-DD') : '',
          timings: data?.timings?.filter((time) => time !== 'anytime'),
        };
      });

    const jobMedias = tenantEntryDetails?.job_medias?.map(
      (item) => item?.job_media_id,
    );
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const payload = {
      is_emergency: tenantEntryDetails?.is_emergency ? 'yes' : 'no',
      specific_access_notes: formData?.specificAccessNote || '',
      door_code: formData?.doorCode,
      how_to_access: formData?.propertyAccess,
      description: tenantEntryDetails?.description,
      service_category: tenantEntryDetails?.service_category?.slug,
      zip_code: tenantEntryDetails?.residentInfo?.zip_code,
      job_media_ids: jobMedias,
      job_schedules: !tenantEntryDetails?.is_emergency ? jobSchedules : [],
      timezone,
    };
    createWorkOrder(payload).then(() => {
      setIsFormSubmitting(false);
      navigate('/success');
    }).catch((err) => {
      setIsFormSubmitting(false);
      catchHandler(err, setError);
    });
  };

  useEffect(() => {
    if (!isEmpty(tenantEntryDetails)) {
      reset(buildFormData(tenantEntryDetails));
    }
  }, [buildFormData, reset, propertyAccessOptions, tenantEntryDetails]);

  useEffect(() => {
    if (!isEmpty(errors?.general_errors?.message?.messages)) {
      errors?.general_errors?.message?.messages?.forEach((error) => {
        toast(error, { type: 'error' });
        clearErrors();
      });
    }
    if (!isEmpty(errors?.general_errors?.message?.dates)) {
      document
        ?.getElementById(
          `schedule-date-${errors?.general_errors?.message?.dates[0]}`,
        )
        .scrollIntoView({
          behavior: 'smooth',
          block: 'end',
          inline: 'nearest',
        });
    }
  }, [
    errors?.general_errors?.message?.messages,
    errors?.general_errors?.message?.dates,
    clearErrors,
  ]);

  useEffect(() => {
    setIsPropertyAccessDetailsFetching(true);
    getPropertiesAccessDetails().then((res) => {
      setIsPropertyAccessDetailsFetching(false);
      setPropertyAccessOptions(res?.how_to_access_property);
    }).catch(() => {
      setIsPropertyAccessDetailsFetching(false);
    });
  }, [setError, dispatch]);

  return (
    <FormProvider {...methods}>
      <Form className="info-form" onSubmit={handleSubmit(onSubmit)}>
        <div className="step">Step 5/5</div>
        <h2>Property Access Details</h2>
        <p>Please confirm access information.</p>
        {isPropertyAccessDetailsFetching ? (
          <Loader />
        ) : (
          <div className="property-access-details-form">
            {Object.keys(propertyAccessOptions)?.map((item) => {
              const data = propertyAccessOptions[item];
              return (
                <React.Fragment key={item}>
                  <FormGroup check>
                    <CustomRadioButton
                      name="propertyAccess"
                      type="radio"
                      value={item}
                      clearError
                      fieldValue={item}
                      hideFormError
                    />
                    {' '}
                    <Label check>
                      <div className="circle">
                        <div className="circle-selected" />
                      </div>
                      <p>{data}</p>
                    </Label>
                    {propertyAccessFormData === 'digital-key-code'
                      && item === 'digital-key-code' ? (
                        <FormGroup className="digital-key-note">
                          <InputField
                            id=""
                            name="doorCode"
                            clearError
                            placeholder="Enter Digital Key"
                            type="text"
                          />
                          <ErrorMessage
                            errors={errors}
                            name="propertyAccess"
                            render={({ message }) => (
                              <small className="text-danger">{message}</small>
                            )}
                          />
                        </FormGroup>
                      ) : (
                        ''
                      )}
                  </FormGroup>
                </React.Fragment>
              );
            })}
            <FormGroup className="specific-access-wrapper">
              <Label>
                Specific Access Note
              </Label>
              <InputField
                name="specificAccessNote"
                type="textarea"
                clearError
                className="specific-access-note"
                placeholder="Enter any access notes here"
              />
            </FormGroup>
          </div>
        )}
        <div className="footer-action">
          <div className="container d-flex gap-3">
            <Button
              type="button"
              color="secondary"
              block
              disabled={isFormSubmitting}
              onClick={() => navigate('/availability')}
            >
              Back
            </Button>
            <Button
              type="submit"
              color="primary-bh"
              block
              disabled={isFormSubmitting}
            >
              {isFormSubmitting ? <LoaderRound /> : 'Submit Request'}
            </Button>
          </div>
        </div>
      </Form>
    </FormProvider>
  );
};

export default AddPropertyAccessDetails;
