import React, {
  forwardRef, useCallback, useEffect, useState,
} from 'react';
import {
  Button,
  Form,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
} from 'reactstrap';
import * as yup from 'yup';
import { isEmpty } from 'lodash';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment';

import { bhTimeWindows } from '../../config';
import { setAvailabilities } from '../../reducers/tenant-job-entry';
import DatePickerField from '../../shared/form-field/date-picker';
import CustomCheckbox from '../../shared/form-field/custom-checkbox-field';
import InputField from '../../shared/form-field/input-field';

const CustomInput = forwardRef(({ onClick, value }, ref) => (
  <input
    onClick={onClick}
    value={value}
    type="text"
    className="date-picker-input"
    placeholder="Click to select Date"
    readOnly
  />
));

const AddEditAvailabilityModal = (props) => {
  const {
    closeModal,
    availability,
    availabilityKey,
    clearError,
  } = props;

  const defaultValues = availability?.[availabilityKey];
  const dispatch = useDispatch();
  const [excludeDates, setExcludeDates] = useState([]);

  const schema = yup.object().shape({
    date: yup.string().required('Please select a availability date').nullable(),
    timings: yup.array().min(1, 'Please select at least one arrival window'),
  });

  const formErrors = useSelector((state) => state.tenantJobEntry?.summaryFormErrors);

  const buildFormData = useCallback((data) => {
    const formData = {};
    const date = data?.date ? new Date(data?.date) : null;
    formData.date = date || '';
    formData.timings = data?.timings || [];
    formData.constraints = data?.constraints || '';
    return formData;
  }, []);

  const methods = useForm({
    defaultValues: buildFormData(defaultValues),
    resolver: yupResolver(schema),
  });

  const {
    handleSubmit,
    formState,
    reset,
  } = methods;

  const { errors } = formState;

  useEffect(() => {
    reset(buildFormData(defaultValues));
  }, [buildFormData, reset, defaultValues]);

  const onSubmit = (formData) => {
    const newAvailability = { ...availability };
    newAvailability[availabilityKey] = formData;
    dispatch(setAvailabilities(newAvailability));

    if (formErrors?.job_schedules?.dates) {
      clearError();
    }
    closeModal();
  };

  useEffect(() => {
    const excludeScheduleWindows = Object.keys(availability)?.filter(
      (item) => item !== availabilityKey,
    );
    const newExcludeDates = [];
    if (!isEmpty(excludeScheduleWindows)) {
      excludeScheduleWindows?.forEach((key) => {
        const scheduleWindow = availability[key];
        if (scheduleWindow?.date) {
          const date = moment(scheduleWindow?.date).format('YYYY-MM-DD');
          newExcludeDates.push(date);
        }
      });
    }
    const currentTime = moment().valueOf();
    const finalTiming = moment().startOf('day').add(12, 'hours').valueOf();
    // this is to exclude today date from calender if current time passed last timing
    if (currentTime > finalTiming) {
      const date = moment().format('YYYY-MM-DD');
      newExcludeDates.push(date);
    }
    setExcludeDates(newExcludeDates);
  }, [availability, availabilityKey]);

  const isExcludeDate = useCallback((date) => {
    const dateFormat = moment(date);
    if (excludeDates?.includes(dateFormat?.format('YYYY-MM-DD'))) {
      return false;
    }
    if (dateFormat?.day() === 0 || dateFormat?.day() === 6) {
      return false;
    }
    return true;
  }, [excludeDates]);

  return (
    <Modal
      isOpen
      toggle={closeModal}
      centered
      wrapClassName="bh-availability-date-modal bh-common-modal"
    >
      <ModalHeader toggle={closeModal} />
      <ModalBody>
        <FormProvider {...methods}>
          <Form className="info-form" onSubmit={handleSubmit(onSubmit)}>
            <h2>Select Date and Time</h2>
            <div className="data-picker-outer">
              <Label for="">
                Select Arrival Date
              </Label>
              <DatePickerField
                customInput={<CustomInput />}
                name="date"
                dateFormat="EEE MMM dd, yyyy"
                filterDate={(date) => isExcludeDate(date)}
                minDate={moment().add(1, 'day').toDate()}
                withPortal
              />
            </div>
            <div className="arrival-time-section">
              <h4>Select Arrival Window</h4>
              <p>The more options the better</p>
              <div className="arrival-time-outer">
                <div className="arrival-time-wrapper">
                  {Object.keys(bhTimeWindows)?.map((item) => {
                    const { label, timeSlot } = bhTimeWindows[item];
                    return (

                      <FormGroup check className="arrival-time arrival-time-item" key={item}>
                        <CustomCheckbox
                          name="timings"
                          hideFormError
                          handleOnchange={(event, value, onChange) => {
                            if (event?.target?.checked) {
                              const newValue = [...value];
                              newValue.push(event?.target?.value);
                              if (newValue.includes('anytime')) {
                                if (!newValue.includes('morning')) {
                                  newValue.push('morning');
                                }
                                if (!newValue.includes('afternoon')) {
                                  newValue.push('afternoon');
                                }
                                if (!newValue.includes('evening')) {
                                  newValue.push('evening');
                                }
                              }
                              if (newValue.includes('morning') && newValue.includes('afternoon') && newValue.includes('evening')) {
                                if (!newValue.includes('anytime')) {
                                  newValue.push('anytime');
                                }
                              }
                              onChange(newValue);
                            } else {
                              let newValue = [...value];
                              if (event?.target?.value === 'anytime') {
                                if (newValue.includes('morning')) {
                                  newValue = newValue?.filter((obj) => (obj !== event?.target?.value && obj !== 'morning'));
                                }
                                if (newValue.includes('afternoon')) {
                                  newValue = newValue?.filter((obj) => (obj !== event?.target?.value && obj !== 'afternoon'));
                                }
                                if (newValue.includes('evening')) {
                                  newValue = newValue?.filter((obj) => (obj !== event?.target?.value && obj !== 'evening'));
                                }
                              }
                              if (event?.target?.value === 'morning' || event?.target?.value === 'afternoon' || event?.target?.value === 'evening') {
                                if (newValue.includes('anytime')) {
                                  newValue = newValue?.filter((obj) => (obj !== event?.target?.value && obj !== 'anytime'));
                                }
                              }
                              newValue = newValue?.filter((obj) => (obj !== event?.target?.value));
                              onChange(newValue);
                            }
                          }}
                          fieldValue={item}
                          id="checkbox2"
                          type="checkbox"
                          value={item}
                        />
                        <Label check>
                          <div className="virtual-checkbox" />
                          <div className="form-check-label-content">
                            <span className="date">{label}</span>
                            <span>{timeSlot}</span>
                          </div>
                        </Label>
                      </FormGroup>
                    );
                  })}
                </div>
                {errors?.timings?.message ? (
                  <small className="text-danger">
                    {errors?.timings?.message}
                  </small>
                ) : (
                  ''
                )}
              </div>
              <FormGroup className="arrival-time arrival-time-constraints">
                <Label for="">
                  Time Constraints (Optional)
                </Label>
                <InputField
                  className="time-constraints-info"
                  name="constraints"
                  placeholder="Example: Anytime after 9am."
                  type="text"
                />
              </FormGroup>
              <div className="modal-action">
                <Button type="submit" color="primary" className="btn-primary-bh" block>
                  Confirm
                </Button>
                <Button color="secondary" block onClick={closeModal}>
                  Close
                </Button>
              </div>
            </div>
          </Form>
        </FormProvider>
      </ModalBody>
    </Modal>
  );
};

export default AddEditAvailabilityModal;
